<template>
  <v-dialog
    v-model="showModal"
    width="700"
    persistent
    class="create-job-sidebar"
    right
  >
    <v-card
      color="#fff"
      class="pa-3"
    >
      <h2 class="title-style pt-3">
        <v-icon
          class="icon-style"
          left
        >
          mdi-pencil
        </v-icon>Create Job
      </h2>
      <p class="sub-heading-creation">
        Please fill in the required fields marked with an asterisk (*) and click the "Create" button to add a new job.
      </p>
      <v-form
        ref="form"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="jobTitle"
                label="Job Title*"
                class="field-style"
                dense
                outlined
                :rules="[(v) => !!v || 'This field is required']"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="description"
                outlined
                dense
                label="Description"
                class="field-style"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="jobType"
                :items="jobTypeList"
                label="Select Job Type *"
                outlined
                dense
              />
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="startDialog"
                v-model="startDateDialog"
                :return-value.sync="startDate"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Planned Start Date*"
                    :rules="[(v) => !!v || 'This field is required']"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    class="field-style"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  color="#37474F"
                  :min="minStartDate"
                  @input="$refs.startDialog.save(date)"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="startDateDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endDialog"
                v-model="endDateDialog"
                :return-value.sync="endDate"
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="endDate"
                    label="Planned End Date*"
                    :rules="[(v) => !!v || 'This field is required']"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    class="field-style"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  scrollable
                  color="#37474F"
                  :min="minStartDate"
                  @input="$refs.endDialog.save(date)"
                >
                  <v-spacer />
                  <v-btn
                    text
                    color="primary"
                    @click="endDateDialog = false"
                  >
                    Cancel
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="parentJobId"
                item-text="name"
                item-value="id"
                :items="JobList"
                label="Select Parent Job"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="amount"
                label="Amount"
                outlined
                dense
                class="field-style"
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="companyId"
                item-text="company.name"
                item-value="company.id"
                :items="projectCompanies"
                label="Assign a Company"
                outlined
                dense
              />
            </v-col>

            <v-col cols="12">
              <v-select
                v-model="memberId"
                :items="CompanyUsers"
                :item-text="memberName"
                item-value="app_user_organization_id"
                label="Assign a Member"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="priority"
                :items="priorityList"
                label="Select a Prority"
                outlined
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="status"
                :items="statusList"
                label="Select a Status"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="createJob()"
            >
              Create
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aeaeae"
              @click="showModal = false"
            >
              cancel
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
<script>
import moment from 'moment';
import Constants from 'src/constants';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
    components: {
      'centre-spinner': spinner,
    },
    data () {
        return {
            showModal: true,
            jobTitle: '',
            description: '',
            startDate: null,
            endDate: null,
            parentJobId: '',
            amount: null,
            companyId: null,
            contractorId: '',
            memberId: null,
            status: '',
            jobType: '',
            jobTypeList: ['milestone', 'task', 'activity'],
            startDateDialog: false,
            endDateDialog: false,
            minStartDate: moment().format('YYYY-MM-DD'),
            date: '',
            companyList: [],
            contractorList: [],
            memberList: [],
            priorityList: ['low', 'medium', 'high', 'critical'],
            statusList: ['pending', 'completed', 'suspended', 'delayed'],
            loading: false,
            priority: '',
        };
    },
    computed: {
    JobList () {
      return this.$store.getters['jobs/getJobList'];
    },
    CompanyUsers () {
      return this.$store.getters['companies/getCompanyUsers'];
    },
    projectCompanies () {
        return this.$store.getters['projects/getProjectCompanies'];
      },
  },
    watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
    companyId (newVal, oldVal) {
      this.getCompanyUsers(newVal);
    },
  },
  async mounted () {
    this.loading = true;
    await this.getJobList();
    await this.getProjectCompanies();
    this.loading = false;
  },
  methods: {
    memberName (e) {
        return `${e.first_name} ${e.last_name}`;
      },
      async getProjectCompanies () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchProjectCompanies', {
          projectId: this.$route.params.projectId,
        }).catch(() => {
          this.loading = false;
        });
        this.loading = false;
      },
    async getCompanyUsers (companyid) {
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('companies/fetchCompanyUsers', { companyId: companyid }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async getJobList () {
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('jobs/fetchJobsByProject', { projectid: this.$route.params.projectId }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
   async createJob () {
      if (this.$refs.form.validate() === false) {
        this.$store.dispatch('alert/onAlert', {
        message: 'Please fill the required fields before proceeding.',
        type: Constants.ALERT_TYPE_ERROR,
        }, { root: true });
        return;
      }
      this.loading = true;
      await this.$store.dispatch('jobs/createJob', {
        name: this.jobTitle,
        description: this.description,
        planned_start_date: this.startDate,
        planned_end_date: this.endDate,
        start_date: this.startDate,
        end_date: this.endDate,
        status: this.status,
        type: this.jobType,
        priority: this.priority,
        assigned_to_id: this.memberId ? this.memberId : this.companyId,
        assigned_to_type: this.memberId === null ? 'company' : 'app-user',
        amount: Number(this.amount),
        parent_job_id: this.parentJobId,
        template_id: this.$route.params.projectId,
        template_type: 'project',
      }).then(response => {
          this.$store.dispatch('alert/onAlert', {
          message: 'New Job has been created successfully.',
          type: Constants.ALERT_TYPE_SUCCESS,
        });
          if (response.assigned_to_id) {
            this.createJobReq(response);
          }
          this.loading = false;
          this.$emit('success');
          this.showModal = false;
        }).catch(() => {
        this.loading = false;
      });
    },
    async createJobReq (response) {
      this.loading = true;
      await this.$store.dispatch('jobs/createRequest', { jobId: response.external_id, planned_date: response.planned_start_date, sent_to: response.assigned_to_id }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 32px !important;
}
.field-style{
  margin-top: -10px;
}
.create-job-sidebar {
    position: absolute !important;
    right: 0;
    top: 0;
    max-width: 650px;
}
.sub-heading-creation {
    margin-bottom: 6px !important;
    color: #aaa !important;
    margin-left: 21px;
    margin-right: 21px;
}
.v-select-list{
  text-transform: capitalize;
}
</style>
