<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              :hint="isSearchAdded ? searchHint : ''"
              append-icon="mdi-magnify"
              outlined
              dense
              @keyup.enter="handleSearch"
              @keydown.esc="handleSearchClear"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <p
        v-if="!isSearchAdded"
        class="info-text"
      >
        <v-icon
          small
          style="color:red;"
        >
          mdi-flash-triangle-outline
        </v-icon> Indicates Milestone <span class="division-style"> | </span>
        <v-icon
          small
          style="color:darkgreen"
        >
          mdi-check
        </v-icon>Indicates Task<span class="division-style"> | </span>
        <v-icon
          small
          style="color:dodgerblue"
        >
          mdi-clipboard-list-outline
        </v-icon> Indicates Activity<span class="division-style" />
      </p>
      <v-data-table
        :headers="headers"
        :items="allJobs"
        class="elevation-1 table-jobs"
        disable-sort
        mobile-breakpoint="100"
        :items-per-page="itemsPerPage"
        :server-items-length="totalRecords"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
        }"
        :options.sync="options"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              <span
                v-if="item.type === 'milestone'"
              >
                <v-icon
                  small
                  style="color:red;"
                >mdi-flash-triangle-outline</v-icon>
              </span>
              <span
                v-if="item.type === 'task'"
              ><v-icon
                small
                style="color:darkgreen"
              >mdi-check</v-icon></span>
              <span
                v-if="item.type === 'activity'"
              >
                <v-icon
                  small
                  style="color:dodgerblue"
                >mdi-clipboard-list-outline</v-icon>
              </span>
              {{ item.name }}
            </td>
            <td>
              {{ item.planned_start_date | convertToLocal }}
            </td>
            <td>
              {{ item.planned_end_date | convertToLocal }}
            </td>
            <td>
              <v-icon :color="getPriorityColor(item.priority)">
                mdi-circle-medium
              </v-icon>
              {{ item.priority }}
            </td>
            <td>
              <span class="currency-style"> {{ projectDetails.currency }} </span>
              <span style="color: green;">{{ item.amount }}</span>
            </td>
            <td>
              <v-chip
                :color="getStatusColor(item.status)"
                class="font-my-style"
                outlined
                small
              >
                {{ item.status | statusFormat }}
              </v-chip>
            </td>
            <td>
              {{ item.created_at | convertToDateTime }}
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                color="#37474F"
                icon
                @click="showJobDetails(item.external_id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#C62828"
                @click.stop
                @click="deleteJob(item.external_id)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <delete-job
      v-if="showDelete"
      :title="'Delete this job ?'"
      :message="'Action will permanently remove this job from this project, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>
<script>
import moment from 'moment';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import Constants from 'src/constants';

export default {
    name: 'JobsList',
    components: {
      'centre-spinner': spinner,
      'delete-job': DeleteDialog,
    },
    filters: {
        arrangePretty (val) {
          return val.join(', ');
        },
        convertToDateTime (stringDatetime) {
          return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
        },
        convertToLocal (stringDatetime) {
          return moment(stringDatetime).local().format('Do MMMM YYYY');
        },
      statusFormat (val) {
        if (val === 'active') {
        return 'Active*';
      } else if (val === 'completed') {
        return 'Completed';
      } else if (val === 'pending') {
        return 'Pending';
      } else if (val === 'suspended') {
        return 'Suspended';
      } else if (val === 'delayed') {
        return 'Delayed';
      } else {
        return val;
      }
    },
    priorityFormat (val) {
      if (val === 'low') {
        return 'Low';
      } else if (val === 'medium') {
        return 'Medium';
      } else if (val === 'high') {
        return 'High';
      } else if (val === 'critical') {
        return 'Critical';
      } else {
        return val;
      }
    },
    },
    mixins: [global],
    props: {
      refreshlist: {
        type: Boolean,
      },
    },
    data () {
        return {
            showForm: false,
            loading: false,
            headers: [
                { text: 'Job Title', align: 'start', value: 'job_title' },
                { text: 'Planned Start Date', value: 'planned_start_date' },
                { text: 'Planned End Date', value: 'planned_end_date' },
                { text: 'Priority', value: 'priority' },
                { text: 'Amount', value: 'amount' },
                { text: 'Status', value: 'status' },
                { text: 'Added on', value: 'created_at' },
                { text: 'More', value: 'more' },
                { text: 'Delete', value: 'delete' },
            ],
            showDelete: false,
            search: '',
            jobId: '',
            updateProgress: false,
            page: 1,
            itemsPerPage: 10,
            pageStart: 0,
            options: {},
        };
    },
    computed: {
      isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
        return true;
      },
      allJobs () {
         const list = this.$store.getters['jobs/getPaginatedJobs'];
         return list.data;
      },
      paginatedJobs () {
         return this.$store.getters['jobs/getPaginatedJobs'];
      },
      totalRecords () {
        if (Object.keys(this.paginatedJobs).length > 0) {
            return this.paginatedJobs.total;
        }
          return 0;
      },
      projectDetails () {
         return this.$store.getters['projects/getProjectDetails'];
      },
      searchHint () {
       return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
      },
    },
    watch: {
      options: {
        async handler () {
          await this.paginatedCall();
        },
        deep: true,
      },
      refreshlist: function (newVal, oldVal) {
        if (newVal === true) {
          this.getJobsList();
        }
      },
    },
    async mounted () {
      await this.getJobsList();
    },
    methods: {
      //   async getProjectJobs () {
      //     await this.$store.dispatch('jobs/fetchJobsList', {
      //       projectId: this.$route.params.projectId,
      //   });
      // },
      showJobDetails (jobId) {
        this.$emit('job-details', jobId);
      },
    async getJobsList () {
      this.$emit('update:refreshlist', true);
      this.loading = true;
      await this.$store.dispatch('jobs/fetchPaginatedJobs', {
        projectId: this.$route.params.projectId,
        params: {
          params: {
            name: this.search === '' ? null : this.search,
            limit: this.itemsPerPage,
            startPage: this.pageStart,
          },
        },
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
      this.$emit('update:refreshlist', false);
    },
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
      await this.getJobsList();
    },
    getPriorityColor (priorty) {
        if (priorty === 'high') {
          return '#E67E22'; // Pumkin Orange
        } else if (priorty === 'low') {
            return '#2ECC71'; // Emerald Green
        } else if (priorty === 'medium') {
            return '#F1C40F'; // Golden Yellow
        } else {
            return '#C0392B'; // Dark red
        }
      },
      getStatusColor (val) {
        if (val === 'active') {
          return '#4CAF50';
        } else if (val === 'pending') {
          return '#FF9800 ';
        } else if (val === 'completed') {
          return '#2196F3 ';
        } else if (val === 'delayed') {
          return '#F44336 ';
        } else {
          return '#9E9E9E ';
        }
      },
      deleteJob (id) {
        this.jobId = id;
        this.showDelete = true;
      },
      deleteConfirmed () {
          this.loading = true;
          this.$store.dispatch('jobs/deleteJob', {
            jobId: this.jobId,
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Job deleted successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.showDelete = false;
            this.loading = false;
            this.getJobsList();
          }).catch(() => {
            this.loading = false;
          });
        },
        async handleSearchClear () {
      this.pageStart = 0;
      this.options.page = 1;
      this.search = null;
      await this.getJobsList();
    },
    async handleSearch () {
      this.pageStart = 0;
      this.options.page = 1;
      await this.getJobsList();
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.table-jobs {
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
  margin-top: 0px !important;
}
.action-btn {
  background: #fff !important;
}
.status-style {
  font-weight: bold;
}
.font-my-style {
  text-transform: capitalize;
  font-weight: bold;
}
.info-text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-style: italic;
  color: #37474F;
  margin-left: 15px;
  margin-top: -20px;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.division-style {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
