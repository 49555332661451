<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!-- <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-chart-box-outline
      </v-icon> Project Schedules
    </h2> -->
    <v-card
      color="#fff"
      class="project-list-card mt-0"
    >
      <br>
      <v-tabs v-model="jobsTabs">
        <v-tab v-if="isJobSchedulesExist">
          Schedule
        </v-tab>
        <v-tab v-if="isJobCashflowExist">
          Cash flow
        </v-tab>
        <v-tab v-if="isJobSchedulesExist">
          Requests
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="jobsTabs">
        <v-tab-item>
          <v-col
            class="text-right table-top-border"
          >
            <v-btn
              class="ma-2"
              outlined
              color="teal"
              icon
              @click="showGanttView()"
            >
              <v-icon>mdi-chart-gantt</v-icon>
            </v-btn>
            <v-btn
              class="ma-2"
              outlined
              color="teal"
              icon
              @click="showListView()"
            >
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-btn>
            <v-btn
              v-if="!showNoBudgetAlert"
              color="#37474f"
              @click="saveChanges()"
            >
              <v-icon class="mr-2">
                mdi-content-save-outline
              </v-icon>
              Save
            </v-btn>
            <v-btn
              color="#000"
              class="ml-2"
              @click="showJobCreateDialog = true"
            >
              <v-icon class="mr-2">
                mdi-plus-circle-outline
              </v-icon>
              Create Job
            </v-btn>
          </v-col>
          <div
            v-show="GanttChart"
            id="gantt_here"
            v-if="jobList.length !==0"
          />
          <div class="d-flex justify-center align-center" v-else>
            <v-icon>mdi-chart-gantt</v-icon>
            <h2 class="mb-0">There is no schedule to list.</h2>
          </div>
          <div
            v-show="ListView"
             v-if="jobList.length !==0"
          >
            <job-list
              :refreshlist="refreshList"
              @job-details="showEditJob"
              @update:refreshlist="refreshList = $event"
            />
          </div>
          <div class="d-flex justify-center align-center" v-else>
            <v-icon>mdi-format-list-bulleted-square</v-icon>
            <h2 class="mb-0">There is no jobs to list.</h2>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="d-flex justify-center align-center stay-tuned-msg">
            <h2 class="mb-0 mt-5">Its an upcoming feature.stay tuned 📺</h2>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-col
            class="text-right table-top-border"
          />
          <div class="no-file-alert">
            <h4 v-if="showNoBudgetAlert">
              No Jobs found.
            </h4>
          </div>
          <request-list />
        </v-tab-item>
      </v-tabs-items>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <budget-details
      v-if="showBudgetDetails"
      :budget-id="budgetId"
      @closed="showBudgetDetails = false"
      @update-budget="editBudgetDetails"
    />
    <edit-budget-details
      v-if="showEditDialog"
      :budget-id="budgetId"
      @closed="showEditDialog = false"
      @budget-details-updated="getProjectBudgets"
    />
    <create-job
      v-if="showJobCreateDialog"
      @closed="showJobCreateDialog = false"
      @success="getJobList"
    />
    <edit-job
      v-if="showEditJobDialog"
      :job-id="jobId"
      @closed="showEditJobDialog = false"
      @success="getJobList"
    />
  </v-container>
</template>

<script>
  import gantt from 'dhtmlx-gantt';
  import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import BudgetDetails from './BudgetDetails.vue';
  import EditBudgetDetails from '../budgets/EditBudgetDetails.vue';
  import Constants from 'src/constants';
  import CreateJob from './CreateJob.vue';
  import EditJob from './EditJob.vue';
  import ModuleHelper from 'src/helpers/module-helper';
  import JobsList from './JobsList.vue';
  import RequestsList from './RequestsList.vue';
  export default {
    name: 'ProjectSchedulingChart',
    components: {
      'centre-spinner': spinner,
      'budget-details': BudgetDetails,
      'edit-budget-details': EditBudgetDetails,
      'create-job': CreateJob,
      'edit-job': EditJob,
      'job-list': JobsList,
      'request-list': RequestsList,
    },
    data () {
      return {
        tasks: {
          data: [],
          links: [],
        },
        coloumns: [
          { name: 'wbs', label: 'WBS', min_width: 50, width: 50, template: gantt.getWBSCode, resize: true },
          { name: 'text', label: 'Task name', tree: true, min_width: 180, width: 200, resize: true },
          { name: 'start_date', label: 'Start', align: 'center', width: 100, resize: true },
          {
          name: 'end_date',
          label: 'End',
          align: 'center',
          width: 100,
          resize: true,
          },
          { name: 'duration', align: 'center', width: 80, resize: true },
        ],
        loading: false,
        showBudgetDetails: false,
        jobId: '',
        showEditDialog: false,
        jobsTabs: null,
        GanttChart: true,
        ListView: false,
        showJobCreateDialog: false,
        showEditJobDialog: false,
        helper: new ModuleHelper(),
        refreshList: false,
      };
    },
    computed: {
      projectDetails () {
        return this.$store.getters['projects/getProjectDetails'];
      },
      jobList () {
        const list = this.$store.getters['jobs/getJobList'];
        return list;
      },
      isJobSchedulesExist () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_JOBS, Constants.SUB_MODULE_JOBS_SCHEDULE));
      },
      isJobCashflowExist () {
        return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_PROJECTS, Constants.SUB_MODULE_JOBS, Constants.SUB_MODULE_JOBS_CASHFLOW));
      },
      projectBudgetLinks () {
        return this.$store.getters['projects/getProjectBudgetLinks'];
      },
      showNoBudgetAlert () {
        if (this.jobList.length === 0) {
          return true;
        }
        return false;
      },
      // isJobInvoiceExist () {
      //   return (this.helper.isSubModuleExistUnderSubModule(Constants.MODULE_JOBS, Constants.SUB_MODULE_JOBS_INVOICE));
      // },
    },
    watch: {
      GanttChart (value) {
        this.GanttChart = value;
      },
    },
    async mounted () {
      await this.getJobList();
    },
    methods: {
      showEditJob (jobid) {
        this.jobId = jobid;
        this.showEditJobDialog = true;
      },
      showGanttView () {
        this.GanttChart = true;
        this.ListView = false;
      },
      showListView () {
        this.GanttChart = false;
        this.ListView = true;
      },
      async getJobList () {
      this.refreshList = true;
      this.loading = true;
      this.selectedHub = null;
      await this.$store.dispatch('jobs/fetchJobsByProject', { projectid: this.$route.params.projectId }).then(response => {
        this.getBudgetsScheduleLinks();
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
      async getBudgetsScheduleLinks () {
        this.loading = true;
        await this.$store.dispatch('projects/fetchBudgetsScheduleLinks', {
          projectId: this.$route.params.projectId,
        }).then(response => {
          if (this.isJobSchedulesExist) {
            this.initGantt();
          }
        }).catch(() => {
          this.loading = false;
        });
      },
      getRandomColor () {
        const colors = ['#71DCE0', '#91DA9C', '#A485E3'];
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
      },
      initGantt () {
        this.loading = true;
        this.tasks.data = [];
        this.tasks.links = [];
        gantt.config.columns = this.coloumns;
        if (this.showNoBudgetAlert === true) {
          this.loading = false;
          return;
        }
        this.tasks.links = this.projectBudgetLinks;
        for (const job of this.jobList) {
          if (this.tasks.data.some(item => item.id === job.id)) {
            return;
          }
              this.tasks.data.push({
                  id: job.id,
                  text: job.name,
                  start_date: new Date(job.planned_start_date),
                  end_date: new Date(job.planned_end_date),
                  progress: this.getProgressPercentage(job.progress),
                  type: job.type === 'milestone' ? 'project' : job.type,
                  parent: job.parent_job_id !== null ? job.parent_job_id : 0,
                  extId: job.external_id,
              });
      };
        this.tasks.links = this.projectBudgetLinks;
        gantt.templates.task_text = function (start, end, task) {
          return task.text + ' (' + Math.round(task.progress * 100) + '%)';
        };
        gantt.templates.scale_cell_class = function (date) {
          if (date.getDay() === 0 || date.getDay() === 6) {
            return 'weekend';
          }
        };
        gantt.templates.timeline_cell_class = function (item, date) {
          if (date.getDay() === 0 || date.getDay() === 6) {
            return 'weekend';
          }
        };
        gantt.attachEvent('onTemplatesReady', function () {
          var toggle = document.createElement('i');
          toggle.className = 'fa fa-expand gantt-fullscreen';
          gantt.toggleIcon = toggle;
          gantt.$container.appendChild(toggle);
          toggle.onclick = function () {
            gantt.ext.fullscreen.toggle();
          };
        });
        gantt.attachEvent('onExpand', function () {
          var icon = gantt.toggleIcon;
          if (icon) {
            icon.className = icon.className.replace('fa-expand', 'fa-compress');
          }
        });
        gantt.attachEvent('onCollapse', function () {
          var icon = gantt.toggleIcon;
          if (icon) {
            icon.className = icon.className.replace('fa-compress', 'fa-expand');
          }
        });
        gantt.config.project_start = this.projectDetails.start_date !== null ? this.projectDetails.start_date : 'N/A';
        gantt.config.project_end = this.projectDetails.end_date !== null ? this.projectDetails.start_end : 'N/A';
        gantt.config.drag_progress = true;
        gantt.config.drag_move = true;
        gantt.config.drag_resize = false;
        gantt.config.details_on_dblclick = false;
        gantt.config.resize_dnd = true;
        gantt.config.date_format = '%d %M %Y';
        gantt.config.resize_rows = true;
        gantt.config.show_grid = true;
        gantt.config.resize_columns = true;
        gantt.config.grid_elastic_columns = true;
        gantt.config.smart_rendering = true;
        gantt.config.autosize = true;
        gantt.plugins({ tooltip: true, marker: true, fullscreen: true });
        // var dateToStr = gantt.date.date_to_str(gantt.config.task_date);
        // var start = new Date(2023, 12, 31);
        // gantt.addMarker({
        //   start_date: start,
        //   css: 'status_line',
        //   text: 'Start project',
        //   title: 'Start project: ' + dateToStr(start),
        // });
        gantt.init('gantt_here');

        gantt.clearAll();

        gantt.parse(this.tasks);
        this.loading = false;

        gantt.attachEvent('onAfterLinkAdd', (id, link) => {
          if (link) {
            this.$store.dispatch('projects/storeProjectBudgetLinks', link);
          }
        });
        gantt.attachEvent('onAfterLinkDelete', (id) => {
          if (id) {
            this.$store.dispatch('projects/deleteStoredBudgetLink', id);
          }
        });

        gantt.attachEvent('onTaskClick', (id, e) => {
          var task = gantt.getTask(id);
          if (e.target.classList.contains('gantt_task_content')) {
            this.jobId = task.extId;
            this.showEditJobDialog = true;
            e.preventDefault();
            return false;
          }
          return true;
        });
        this.loading = false;
      },
      async saveChanges () {
        this.loading = true;
        const budgetLinks = this.projectBudgetLinks;
        await this.$store.dispatch('projects/storeSavedProjectBudgetLinks', {
          projectId: this.$route.params.projectId,
          budgetLinks: {
            schedules: budgetLinks,
          },
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Project schedule saved successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.loading = false;
          this.getProjectBudgets();
        }).catch(() => {
          this.loading = false;
        });
        // this.loading = false;
      },
      getProgressPercentage (totalHours, timeSheetHours) {
        const percentage = (timeSheetHours / totalHours) * 100;
        const roundedPercentage = percentage.toFixed(2);
        return roundedPercentage;
      },
      editBudgetDetails () {
        this.showBudgetDetails = false;
        this.showEditDialog = true;
      },
    },
  };
</script>

<style>
#gantt_here {
  height: 100vh;
  width: 100%;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F !important;
  font-size: 45px !important;
}
.gantt_grid_scale,
.gantt_task_scale,
.gantt_task_vscroll {
background-color: #FFFF;
}

.gantt_grid_scale,
.gantt_task_scale,
.gantt_task .gantt_task_scale .gantt_scale_cell,
.gantt_grid_scale .gantt_grid_head_cell {
color: #37474F;
border-bottom: 1px solid #37474F;
font-weight: bold;
font-size: 12px;
}
.gantt_row.odd{
  background-color:#fff;
}
.gantt_row:hover {
  background-color: #ECEFF1 !important; /* Change to your desired color */
}
.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
background-color: #ECEFF1;
}
.gantt_task .gantt_task_scale .gantt_scale_cell {
  border-right: 1px solid #37474F;
}
.gantt_task_line{
  border-color: rgba(0, 0, 0, 0.35); /* Black color with 25% alpha/opacity */
}
.gantt_task_line .gantt_task_progress {
  background-color: #FA9C9C;
}
.gantt_task_line{
  background-color: #F6DED1;
  box-shadow: none;
  border: none;
  color: #000;
  border: 1px solid #d58f67 ;
}
.gantt_task_line.gantt_task_content {
  color: #000 !important;
}
.gantt_task_progress {
   background: #92E3D7;
}
.gantt_task_line.gantt_project .gantt_task_progress {
  background-color: #92E3D7;
}
.gantt_task_line.gantt_project {
   background: #92E3D7;
}
.gantt_row.gantt_row_project{
  background-color:#ffffff;
  color: #fff;
  font-weight: bold;
}
.gantt_scale_line:nth-child(2){
  background-color:#fff;
}
.task_color_red .gantt_task_bar {
  background-color: #00695C !important;
}
.task_color_green .gantt_task_bar {
  background-color: #01579B !important;
}
.task_color_blue .gantt_task_bar {
  background-color: #E65100 !important;
}
.task_color_yellow .gantt_task_bar {
  background-color: #C62828 !important;
}
.task_color_orange .gantt_task_bar {
  background-color: #37474F !important;
}
.task_color_orange .gantt_task_bar {
  background-color: #FF3700 !important;
}
.gantt_grid .gantt_grid_head_cell {
  border-right: 1px solid #37474F !important; /* Adjust border style as needed */
  border-bottom: 1px solid #37474F;
}
.no-file-alert {
  color: #C62828;
  font-size: 18px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
}
.project-list-card {
  box-shadow: none !important;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.gantt_task_line {
    border-radius: 14px;
}
.gantt_task_line.gantt_milestone .gantt_task_content{
  border-radius: 3px !important;
  color: #000 !important;
}
.gantt_task_line.gantt_milestone {
  background-color: #81b9ff;
  border: 0 solid #3e536c;
}
.gantt_task_content {
  color: #000 !important;
}
.gantt_task_line.gantt_project {
  background-color: #DFF2EF;
  border: 1px solid #47b3a2;
}
.weekend {
  background-color: #e5e5f7;
}
.gantt_task_cell {
    border-right: 1px dashed #ccc !important;
}
.gantt_row, .gantt_task_row{
  border: none;
}
.gantt_tree_icon.gantt_folder_open {
  background-image:url('../../../../assets/target.png') ;
  background-size: 18px;
}
.gantt_tree_icon.gantt_folder_closed {
  background-image:url('../../../../assets/target.png') ;
  background-size: 18px;
}
.gantt_tree_icon.gantt_file {
  background-image:url('../../../../assets/done.png') ;
  background-size: 18px;
}
.stay-tuned-msg {
  border: 1px solid #ccc;
  height: 300px;
  border-radius: 5px;
}
</style>
